<template>
  <div class="w-100 ">
    <section class="w-100 tags" v-if="!isOpenVariant">
      <div class=""
        v-if="areNotAvailableTags"  style="height: 70vh; overflow-y: scroll; overflow-x: hidden;">
        <LoaderComp v-if="isLoadingImport"/>
        <div class="row question-not-font flex-column d-flex justify-content-center align-items-center" v-else>
          
        <img src="https://www.quizell.com/_nuxt/img/why_quizell_male.04fc678.svg" alt="Svg" style="width: 15rem;">
        <div class="row text-center px-2">
          <div class="col-12">
            <h3>Tags Not Available</h3>
          </div>
          <!-- <div class="col-12">
            <h5>You can <a href='javascript:void(0)' 
            @click="()=>{isLoadingImport=true; $emit('importItems',{title:'saveProductTags',callBackFunction:()=>{isLoadingImport=false;}})}">
            import tags</a> from your store</h5>
           
          </div> -->
        </div>
      </div>
      </div>
      <b-skeleton type="input" v-else-if=isLoading></b-skeleton>
      <div class="" v-else>
        <div class="tagSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
          <div style="cursor: pointer">
            <i class="fa fa-search" style="color: #6d6b6d" aria-hidden="true"></i>
            <input type="text" class="mx-2 flex-grow-1" v-model="searchCollection"
              placeholder="Search for Tag" />
          </div>

        </div>
        <!-- <div class="d-flex col-11 p-0 w-100 heading-input-search">
        <label for="search-tag" class="font-weight-bold">Search</label>
        <div class="tagSearchInput w-100 px-0 ml-2">
          <input id="search-tag" class="w-100" type="search" name="search-tags" placeholder="Enter Product"
            v-model=searchCollection />
        </div>
      </div> -->

      </div>

      <div v-if=isLoading class="col-12  mt-4 d-flex justify-content-end">
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12  mt-4 d-flex justify-content-end">

        <div class="filterBtn" v-if="!areNotAvailableTags && getTags.length > 0"
          @click.stop="bulkSelectTags">
          {{ isSelectAll?'Select All': 'Unselect All' }}</div>
      </div>
      <div class="w-100 p-3 tagBox" v-if=isLoading style="border:none">
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :Key="index">
            <div class="d-flex  align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="w-100 p-3 tagBox" v-if="(!areNotAvailableTags) && getTags.length > 0" style="border:none">
        <div :class="[isSelectedProduct(tag) ? 'selectedProduct' : '', 'mb-2 py-1,tag-row']"
          v-for="tag, prodIndex in getTags" :key="tag.id">
          <div class="d-flex  align-items-center tag">
            <div class="d-flex col-8 px-0 align-items-center">
              <div class="">
                <div class="custom-control custom-checkbox" role="group">
                  <input type="checkbox" :id="`quest-${prodIndex}`" class="custom-control-input position-static"
                    aria-label="Never" :value="{
                      product_tag_id: tag.id,
                      question_option_id:
                        question.options[
                          selected.optionIndex
                        ].id,
                    }" v-model="
  question.options[
    selected.optionIndex
  ].selected_tags
" />
                  <label :for="`quest-${prodIndex}`" class="custom-control-label"></label>
                </div>
              </div>
              
              <div class="singleProduct d-flex align-items-center pl-2">
                <p class="title m-0 py-2 text-left">
                  {{ tag.title }}
                </p>
              </div>
            </div>
            <div class=" col float-right">
              <div class="tag-btn" @click="openVariant(tag.title)">
                <div class="btn variant-text">Products <i class="fa-solid ml-1 fa-chevron-right"></i></div>
              </div>
            </div>

          </div>

        </div>
      
      </div>
    
      <div class="w-100 p-3 tagBox" v-if="!this.isLoading && tags.all.length > 0 && getTags <= 0"
        style="border:none">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
          <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchCollection = ''"> <i
              class="fa-solid fa-arrow-rotate-right"></i></div>
          <h5>No tags were found matching your filter </h5>
        </div>
      </div>
    </section>
    <tag-variant v-else @backFromVariant="backFromVariant()" :isLoadingProducts="isLoadingCollectionProducts"
      :TagProducts="tagProducts" :selected="selected"></tag-variant>
  </div>
</template>
<script>
import LoaderComp from "../../customize/component/LoaderComp.vue";
import axios from 'axios'
import TagVariant from './TagVariant.vue'
import { mapGetters } from "vuex";
export default {
  props: {
    tags: Object,
    selected: Object,
    question: Object,
    isLoading: Boolean,
    products:Array
  },
  components: {
    TagVariant,
    LoaderComp
  },
  computed: {
    areNotAvailableTags() {
      return this.isLoading == false && this.tags.all.length <= 0 ? true : false
    },
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID"]),


    getTags() {
   
      return this.tags.all.filter(item => {
        return ((item.title.toLowerCase().includes(this.searchCollection.toLowerCase())))
      });
    }
  },
  data() {
    return {
      isLoadingImport:false,
      isLoadingBtn:false,
      isOpenVariant: false,
      skeletonCount: 5,
      searchCollection: '',
      isSelectAll: true,
      showResetBtn: false,
      tagProducts:[],
      isLoadingCollectionProducts:false
    }
  },
  methods: {

   async openVariant(title) {
    this.isLoadingCollectionProducts=true;
      this.isOpenVariant = true
        await axios.post('previewTagProducts',{tag:title,quizId: parseInt(localStorage.getItem('QuizID'))}).then((response)=>{
        this.tagProducts=response.data.data
        }).catch(()=>{
          this.$toasted.show("Error occurred ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        })
        this.isLoadingCollectionProducts=false;
    },
    backFromVariant() {
      this.isOpenVariant = false
    },
    bulkSelectTags() {
      let tags = this.getTags;
      if (this.isSelectAll) {
        this.isSelectAll = false;
    
        tags.forEach(tag => {
          let checkDuplicate={
            product_tag_id: tag.id,
            question_option_id: this.question.options[
              this.selected.optionIndex
            ].id
          }
          if(!this.question.options[this.selected.optionIndex].selected_tags.includes(checkDuplicate))
          this.question.options[this.selected.optionIndex].selected_tags.push({
            product_tag_id: tag.id,
            question_option_id: this.question.options[this.selected.optionIndex].id
          })
        })
       
      }
      else {
        this.question.options[
          this.selected.optionIndex
        ].selected_tags = [];
        this.isSelectAll = true;
      }

    },


    isSelectedProduct(tag) {

      const findIndex = this.question.options[this.selected.optionIndex].selected_tags.findIndex((x) => x.product_tag_id == tag.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    tagImage(image) {
      if (image)
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    }
  }
}
</script>
<style>
.tag-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.tag-icon {
  color: #4d1b7e;

}

.tag-icon:hover {
  color: black;
}

.tagSearchInput:focus-within {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  outline: 1px solid #4d1b7e;

}

.tagSearchInput:focus-within i {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  color: #4d1b7e !important;

}

.tagSearchInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.tagSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.tagSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.tagBox {
  height: 59vh;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.tagBox .tag {
  border-radius: 6px;
}

.tagBox .tag:hover {
  background: #b3afb614;
}

.tag-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none

}

.selectedProduct .tag:hover .tag-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}
.selectedProduct .tag:hover .tag-btn .variant-text{
  color: #4d1b7e !important;
}
.selectedProduct .tag:hover .tag-btn .tag-text {
  color: #4d1b7e !important;
}

.tag:hover .tag-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>